// In src/prismic-configuration.js
export const linkResolver = doc => {
    // URL for a singles
    if (doc.type === 'single') {
        return `/${doc.tags[0]}/${doc.uid}`;
    }

    // URL for article details
    if (doc.type === 'article') {
        return `/nieuws/${doc.uid}`;
    }

    // URL for a overviews
    if (doc.type === 'team' || doc.type === 'archive' || doc.type === 'articles') {
        return `/${doc.uid}`;
    }

    // Backup for all other types
    return '/';
};

/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import { registerLinkResolver } from '@prismicio/gatsby-source-prismic-graphql';
import { linkResolver } from './src/utils/linkResolver';

import React from 'react'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'

export const wrapRootElement = ({ element }) => {
    return (
        <GoogleReCaptchaProvider reCaptchaKey="6Ld6XeoaAAAAADQU5QC-Gnj5Dm7Qxhdom0iYcn9Q">
            {element}
        </GoogleReCaptchaProvider>
    )
}

registerLinkResolver(linkResolver);
